@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --sidebar-offset: 150px; /* Default offset for medium to large screens */
}

@media (max-width: 1024px) {
  :root {
    --sidebar-offset: 0px; /* Adjust for smaller screens */
  }
}

@media (max-width: 768px) {
  :root {
    --sidebar-offset: 0px; /* Adjust for tablet screens */
  }
}

@media (max-width: 480px) {
  :root {
    --sidebar-offset: 0px; /* No offset for very small screens */
  }
}
